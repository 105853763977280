<template>
  <van-popup
    v-model="showPicker"
    class="fill-in-wrap-pop"
    position="right"
    style="width: 100%; height: 100%"
  >
    <div class="fill-in-wrap">
      <div class="content fillin-upload-ctn">
        <van-cell title-class="fillin-upload-ctn-title">
          <template #title>
            <span v-if="datum.isRequired == 'Y'" class="color-r m_r_8">*</span>
            <span class="custom-title">{{ datum.materialName }}</span>
          </template>
          <template #label>
            <div class="dis_flex ali_it_cen">
              <span
                v-if="datum.exampleUrl"
                class="custom-title color-blue m_r_16"
                @click="previewDemo(datum.exampleUrl)"
              >
                <van-icon name="eye-o" />
                示例
              </span>
              <span class="custom-title"
                >({{ acceptTipsFn(datum.imageType) }})</span
              >
            </div>
          </template>
        </van-cell>
        <div class="p_20_32">
          <Upload
            v-model="images"
            previewVali="mobile"
            :maxCount="count"
            :multiple="count > 1"
            :isDefaultAccept="false"
            :accept="acceptFn(datum.imageType)"
            @upload="saveMaterFn"
            @del="deleteMaterFn"
          />
        </div>
      </div>
      <div class="btns">
        <van-button size="small" @click="handleClose">返回</van-button>
        <van-button type="info" size="small" @click="saveFn">保存</van-button>
      </div>
    </div>
  </van-popup>
</template>
<script>
import { ImagePreview } from "vant";
import { saveMaterialsImg, delMaterialsImg } from "@/api/apply";
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    // 需要填的表单内容
    itemdata: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    Upload: () => import("@/components/Upload.vue")
  },
  watch: {
    showPicker(v) {
      if (v) {
        try {
          this.init();
        } catch (e) {
          console.log("查看报错", e);
        }
      } else {
        this.$emit("update");
      }
    },
    "datum.lists"(list) {
      this.images = (list || []).map((e) => {
        return {
          fileName: e.imageName,
          fileUrl: e.imageUrl,
          name: e.imageName,
          uploaded: true,
          url: e.imageUrl,
          id: e.id
        };
      });

      console.log("images", this.images);
    }
  },
  computed: {
    showPicker: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      }
    }
  },
  data() {
    return { count: 9, pageLoading: false, datum: {}, images: [] };
  },
  created() {},
  methods: {
    init() {
      this.datum = this.itemdata || {};
      const { upperLimit } = this.datum;
      console.log(upperLimit);
      // if(!this.datum.lists)
      // 支持多张code
      // const nine = ["P8", "H14", "P11", "H8", "H11", "P7"];
      // const count = nine.includes(capitalMaterialCode) ? 9 : 1;
      this.count = upperLimit;
      this.getProductConfigs();
    },
    // 产品配置
    async getProductConfigs() {
      try {
        this.pageLoading = true;
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.pageLoading = false;
      }
    },
    previewDemo(url) {
      ImagePreview({ images: [url], closeable: true });
    },
    async saveMaterFn(datum) {
      try {
        this.pageLoading = true;
        this.$toast.loading({
          message: "上传中...",
          forbidClick: true,
          duration: 0
        });
        if (this.datum.isNeed == "1" && !this.images.length) {
          this.$toast("请上传" + this.datum.materialName);
          return;
        }
        const { bizNo, materialCode, materialName, capitalMaterialCode } =
          this.datum;
        const params = {
          bizNo,
          capitalMaterialCode,
          materialCode,
          materialName,
          imageName: datum.name,
          imageUrl: datum.url,
          imageType: this.datum.imageType
        };
        const { data } = await saveMaterialsImg(params);
        this.datum.lists.push(data);
        this.pageLoading = false;
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.$toast.clear();
      }
    },
    async deleteMaterFn(index, item) {
      try {
        await delMaterialsImg(item.id);
        const index = this.datum.lists.findIndex((e) => e.id == item.id);
        this.datum.lists.splice(index, 1);
      } catch (error) {
        this.$errMsg(error);
      }
    },
    async saveFn() {
      // this.$emit("update");
      this.showPicker = false;
    },
    handleClose() {
      // 用户点击关闭图标时触发
      this.showPicker = false;
      this.$nextTick(() => {
        this.showPicker = false;
      });
    },
    acceptFn(fileType) {
      if (fileType == "PIC") {
        return ".jpg";
      } else if (fileType == "VIDEO") {
        return "video/*";
      } else if (fileType == "DOC") {
        return ".pdf,.xlsx,.csv,.xls";
      } else {
        return "image/*,video/*";
      }
    },
    acceptTipsFn(fileType) {
      if (fileType == "PIC") {
        return "请上传.jpg格式文件";
      } else if (fileType == "VIDEO") {
        return "请上传视频格式文件";
      } else if (fileType == "DOC") {
        return "请上传pdf格式文件";
      } else {
        return "image/*,video/*";
      }
    },
    clickPreviewFn(item) {
      this.itemInfo = item || {};
      this.showitemPicker = true;
    }
  }
};
</script>
<style lang="scss" scoped>
.fill-in-wrap-pop {
  :deep(.fill-in-wrap) {
    height: 100%;
    display: flex;
    flex-direction: column;
    .head {
      height: 96px;
      text-align: center;
      line-height: 96px;
      font-size: 32px;
      font-weight: 600;
      color: #333;
      border-bottom: 1px solid #efefef;
    }
    .content {
      flex: 1;
      overflow-y: auto;

      .color-r {
        color: red;
      }
      .color-blue {
        color: rgb(24, 144, 255);
      }
    }
    .fillin-upload-ctn-title {
      min-width: 65vw;
    }
    .btns {
      border-top: 1px solid #efefef;
      height: 112px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      box-shadow: 0 -5px 10px #efefef;
      .van-button {
        min-width: 160px;
      }
    }
  }
}
</style>
